export const environment = {
  name: 'snd',
  production: false,
  version: '2.1.0',
  apiBaseUrl: 'https://tuvisplctrenszw.easyvax.org/api',
  apiLocalStorageErrorKey: 'vax-snd', // update localStorage to include this key with a value of * to force API errors for testing
  apiKey: 'N*$Z0nj}c2bm0M<z', // API key for backbone
  gtagId: '', // Google Tag Mananger tracking
  gFloodlightId: '', // Google Floodlight tracking
  recaptchaKey: '6LeRoQ8pAAAAABUHLjEtzM7LG-df0jVZ7rMswd8i',
  recaptchaAction: 'reserve',
};
